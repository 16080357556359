<template>
  <div>
    <ClientHeader :client="client" />
    <v-row>
  <v-col cols="12">
    <div class=" text-center" v-if="checkItems.length < 1" >
      <h3 class="text-center mb-2">
        <span class="pb-4">There are no current receivables for this client.</span>
      </h3><br/>
      Receivables are generated when a signed return is uploaded to the submissions tab, and a tracking number is entered.
      <p>If this has already been done, contact <a href="mailto:support@patriotdataprocessing.com">support</a>.</p>
    </div>
  </v-col>
  <v-col id="checkItemsContainer" v-for="(item, index) in checkItems" :key="index" cols="12">
        <v-card class="mt-8" :class="{ activeCheck: item.balance === 0 }"  >
          <v-card-title>Quarter: {{item.quarter}}</v-card-title>
          <v-row>
            <v-col cols="6" class="text-start pl-6">
              <h3 class="checkFees">Fee expected: {{item.fee_expected_formatted}}</h3>
              <h3 class="checkFees">Fees received: {{$formatAmount(item.fees_received)}}</h3>
              <h3 :class="{ validBalance:  item.balance === 0,inValidBalance: item.balance !== 0   }"
              >Balance: {{ $formatAmount(item.balance) }}</h3>

            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-column align-start"    v-for="(payment, payIndex) in item.applied_payments" :key="payIndex">
                <h3 style="font-size: 12px;font-weight: 400" class="checkFees">Applied {{ payment.amount_formatted }} from Check {{ payment.check_number }}</h3>
                <h3 style="font-size: 12px;font-weight: 400" v-if="payment.check_issue_date" class="checkFees">on {{payment.check_issue_date}}</h3>
              </div>
            </v-col>
          </v-row>
          <v-divider v-show="item.balance<0"></v-divider>
          <h3 class="px-3 pt-2" style="color: #AF0808;font-weight: 400" v-show="item.balance < 0">This quarter has a negative balance, and may require a refund be issued to the client. Refunds must be manually applied by contacting support.</h3>
        </v-card>
      </v-col>
    <v-divider></v-divider>
  </v-row>
</div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";
export default {
  name: "client-status",
  components: {ClientHeader},
  data: () => ({
    loadingOnPost:false,
    checkItems:[],
    clientId: null,
    client:null,
  }),
  methods:{
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },
    getChecks(){
      this.loadingOnPost = true
      ClientsService.getChecks(this.clientId)
          .then(res=>{
            this.checkItems = res
            this.loadingOnPost = false
            this.checkItems.map(item => {
              //item.creditExpected = item.fee_expected
              item.balance = item.fee_expected
              item.balance -= item.fees_received
            })
          }).catch(err => {
        this.loadingOnPost = false
        this.$func.popMessage(err,'danger')
      })
    },
  },
  created() {
    this.clientId = this.$route.params.clientId
    this.getCurrentClient()
  },
  mounted() {
    this.getChecks()
  },
  }
</script>
